<template>
    <div>
        <inventory></inventory>
    </div>
</template>

<script>
import Inventory from "./inventory/Inventory.vue";
export default {
    components: { Inventory },
};
</script>

<style></style>
